<template>
  <div class="search-sidebar--dropdown">
    <div
      v-b-toggle.collapseCategory
      class="d-flex"
      style="justify-content: space-between;"
      @click="open"
    >
      <h3 class="filter--title">{{ $t('general.filter.category') }}</h3>
      <i :class="!isOpened ? 'ion-chevron-down' : 'ion-chevron-up'"></i>
    </div>
    <b-collapse visible id="collapseCategory">
      <div class="dropdown-item" v-for="(localCategory, key) in categories" :key="`propCat${key}`">
        <div class="custom-control custom-radio">
          <input
            type="radio"
            :id="'propertyCategory' + key"
            v-model="categoryId"
            :value="localCategory.id"
            class="custom-control-input"
            :disabled="isLoading"
          />
          <label class="custom-control-label" :for="'propertyCategory' + key">
            <span>{{ $t('general.' + localCategory.name) }}</span>
          </label>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import SearchMixin from '@/mixins/searchMixin';

export default {
  mixins: [SearchMixin],
  data: () => ({
    isOpened: true,
    fromPage: 'SEARCH',
    isMobileFilter: false,
  }),
  computed: {
    ...mapState({
      categories: state => state.v2.search.categories,
      isLoading: state => state.v2.search.isLoading,
    }),
    ...mapGetters({
      propertyTypes: 'v2/masters/propertyTypesByCategory',
    }),
    categoryId: {
      get() {
        let category = this.$store.state.v2.search.category;
        return category ? category.id : null;
      },
      set(val) {
        this.onCategoryClick(val);
      },
    },
  },
  methods: {
    async onCategoryClick(categoryId) {
      if (this.fromPage === 'HOME') {
        this.$store.commit('v2/search/SET_CATEGORY', { id: categoryId });
      } else {
        // eslint-disable-next-line no-unused-vars
        let query = await this.$store.dispatch('v2/search/setCategory', {
          payload: categoryId,
          query: this.$route.query,
        });
        await this.onPropertyTypeCategoryChange(categoryId);

        // await this.updateRoute(query);

        // Check apakah ganti filter-nya dari sidebar atau bukan, kalau dari sidebar jangan langsung redirect halaman
        if (!this.$store.state.v2.search.activeFilterSidebar) {
          await this.$store.dispatch('v2/search/goToSearchPage', {
            router: this.$router,
            params: this.$route.params,
          });
        }
      }
    },
    open() {
      this.isOpened = !this.isOpened;
    },
    onUpdateFilter() {},
    async onPropertyTypeCategoryChange(categoryId) {
      let allPromises = [];

      allPromises.push(this.$store.dispatch('v2/masters/getPropertyTypeCategories', categoryId));
      allPromises.push(this.$store.dispatch('v2/masters/getPropertyTypesByCategory', categoryId));
      await Promise.all(allPromises);
      this.$store.commit('v2/search/SET_ACTIVE_PROPERTY_TYPES', this.propertyTypes.slice());
    },
  },
};
</script>

<style lang="scss" scoped>
.filter--image {
  height: 20px;
}
</style>
