<template>
  <div class="card--listing-image">
    <router-link :to="linkDetail" style="text-decoration: none" v-if="!useMultiImage">
      <picture>
        <source :srcset="photoUrl" type="image/webp" />
        <source :srcset="getFallbackImage(photoUrl)" type="image/jpeg" />
        <img :src="getFallbackImage(photoUrl)" class="listing--image" :alt="newImageAlt" />
      </picture>
    </router-link>
    <VueSlickCarousel
      class="carousel--wrapper"
      v-else
      v-bind="settings"
      @beforeChange="beforeChange"
      @afterChange="afterChange"
    >
      <div v-for="(photo, index) in photos" :key="index" class="carousel--slide">
        <router-link :to="linkDetail" style="text-decoration: none">
          <picture>
            <source :srcset="photo.url" type="image/webp" />
            <source :srcset="getFallbackImage(photo.url)" type="image/jpeg" />
            <img :src="getFallbackImage(photo.url)" class="listing--image" :alt="newImageAlt" />
          </picture>
        </router-link>
      </div>
      <template #prevArrow>
        <div class="card--listing-prev">
          <img src="@/assets/img/icons/chevron-left.svg" alt="Previous" />
        </div>
      </template>
      <template #nextArrow>
        <div class="card--listing-next">
          <img src="@/assets/img/icons/chevron-right.svg" alt="Next" />
        </div>
      </template>
    </VueSlickCarousel>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  props: {
    linkDetail: {
      default: '/',
      require: true,
    },
    primaryPhoto: {
      default: null,
      require: true,
    },
    photos: {
      default: null,
      require: true,
    },
    allowMultiImage: {
      default: false,
    },
    imageAlt: {
      default: null,
      require: true,
    },
  },
  data: () => ({
    dragging: false,
    settings: {
      dots: false,
      edgeFriction: 0.35,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      // lazyLoad: 'progressive', // Ini kalo barengan multiple rows jadi gak bisa slide
      draggable: false,
    },
  }),
  computed: {
    useMultiImage() {
      return this.photos && this.photos.length > 1 && this.allowMultiImage;
    },
    newImageAlt() {
      return this.imageAlt + ' - Propfix';
    },
    photoUrl() {
      return this.primaryPhoto ? this.primaryPhoto.url : '';
    },
  },
  methods: {
    beforeChange() {
      this.dragging = true;
    },
    afterChange() {
      this.dragging = false;
    },
    clickHandler(e) {
      if (this.dragging) {
        e.preventDefault();
      }
    },
  },
};
</script>
