<template>
  <div class="search-sidebar--dropdown">
    <div
      v-b-toggle.collapsePano
      class="d-flex"
      style="justify-content: space-between;"
      @click="open"
    >
      <h3 class="filter--title">{{ $t('general.filter.panorama') }}</h3>
      <i :class="!isOpened ? 'ion-chevron-down' : 'ion-chevron-up'"></i>
    </div>
    <b-collapse id="collapsePano">
      <div class="dropdown-item">
        <div class="custom-control custom-radio">
          <input
            type="radio"
            id="panoRequiredFalse"
            v-model="panoRequired"
            :value="false"
            class="custom-control-input"
            :disabled="isLoading"
          />
          <label class="custom-control-label" for="panoRequiredFalse">{{
            $t('general.all')
          }}</label>
        </div>
      </div>
      <div class="dropdown-item">
        <div class="custom-control custom-radio">
          <input
            type="radio"
            id="panoRequiredTrue"
            v-model="panoRequired"
            :value="true"
            class="custom-control-input"
            :disabled="isLoading"
          />
          <label class="custom-control-label" for="panoRequiredTrue">{{
            $t('general.filter.only360')
          }}</label>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SearchMixin from '@/mixins/searchMixin';

export default {
  mixins: [SearchMixin],
  data: () => ({
    isOpened: false,
    fromPage: 'SEARCH',
    isMobileFilter: false,
  }),
  computed: {
    ...mapState({
      searchType: state => state.v2.search.searchType,
      isLoading: state => state.v2.search.isLoading,
    }),
    panoRequired: {
      get() {
        return this.$store.state.v2.search.panoRequired;
      },
      set(val) {
        this.onPanoramaClick(val);
      },
    },
  },
  methods: {
    async onPanoramaClick(panoRequired) {
      if (this.fromPage === 'HOME') {
        this.$store.commit('v2/search/SET_PANO_REQUIRED', panoRequired);
      } else {
        let query = await this.$store.dispatch('v2/search/setPanoRequired', {
          payload: panoRequired,
          query: this.$route.query,
        });

        await this.updateRoute(query);
      }
    },
    open() {
      this.isOpened = !this.isOpened;
    },
    onUpdateFilter() {},
  },
};
</script>
