<template>
  <div class="search-sidebar--dropdown">
    <div
      v-b-toggle.collapseListingType
      class="d-flex"
      style="justify-content: space-between;"
      @click="open"
    >
      <h3 class="filter--title">{{ $t('addListing.listingType') }}</h3>
      <i :class="!isOpened ? 'ion-chevron-down' : 'ion-chevron-up'"></i>
    </div>
    <b-collapse visible id="collapseListingType">
      <rent-sell-switch :listingType="listingType" @changeType="changeType"></rent-sell-switch>
      <!--      <div class="dropdown-item">-->
      <!--        <div class="custom-control custom-radio">-->
      <!--          <input-->
      <!--            type="radio"-->
      <!--            id="listingTypeRent"-->
      <!--            v-model="listingType"-->
      <!--            :value="'R'"-->
      <!--            class="custom-control-input"-->
      <!--            :disabled="isLoading"-->
      <!--          />-->
      <!--          <label class="custom-control-label" for="listingTypeRent">{{ $t('general.R') }}</label>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="dropdown-item">-->
      <!--        <div class="custom-control custom-radio">-->
      <!--          <input-->
      <!--            type="radio"-->
      <!--            id="listingTypeBuy"-->
      <!--            v-model="listingType"-->
      <!--            :value="'S'"-->
      <!--            class="custom-control-input"-->
      <!--            :disabled="isLoading"-->
      <!--          />-->
      <!--          <label class="custom-control-label" for="listingTypeBuy">{{ $t('general.S') }}</label>-->
      <!--        </div>-->
      <!--      </div>-->
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import SearchMixin from '@/mixins/searchMixin';
const RentSellSwitch = () => import('@/components/utils/rent-sell-switch');

export default {
  mixins: [SearchMixin],
  components: {
    RentSellSwitch,
  },
  data: () => ({
    isOpened: true,
    fromPage: 'SEARCH',
    isMobileFilter: false,
  }),
  computed: {
    ...mapState({
      isLoading: state => state.v2.search.isLoading,
      category: state => state.v2.search.category,
      propertyTypesOriginal: state => state.v2.masters.propertyTypes,
    }),
    ...mapGetters({
      propertyTypes: 'v2/masters/propertyTypesByCategory',
    }),
    listingType: {
      get() {
        return this.$store.state.v2.search.listingType;
      },
      set(val) {
        this.onListingTypeClick(val);
      },
    },
  },
  methods: {
    async onListingTypeClick(listingType) {
      if (this.fromPage === 'HOME') {
        this.$store.commit('v2/search/SET_LISTING_TYPE', listingType);
      } else {
        // eslint-disable-next-line no-unused-vars
        let query = await this.$store.dispatch('v2/search/setListingType', {
          payload: listingType,
          query: this.$route.query,
        });
        await this.onListingTypeChange();

        // await this.updateRoute(query);

        // Check apakah ganti filter-nya dari sidebar atau bukan, kalau dari sidebar jangan langsung redirect halaman
        if (!this.$store.state.v2.search.activeFilterSidebar) {
          await this.$store.dispatch('v2/search/goToSearchPage', {
            router: this.$router,
            params: this.$route.params,
          });
        }
      }
    },
    open() {
      this.isOpened = !this.isOpened;
    },
    onUpdateFilter() {},
    async changeType(newType) {
      this.listingType = newType;
    },
    async onListingTypeChange() {
      let allPromises = [];
      let categoryId = this.category ? this.category.id : null;
      allPromises.push(this.$store.dispatch('v2/masters/getPropertyTypeCategories', categoryId));
      allPromises.push(this.$store.dispatch('v2/masters/getPropertyTypesByCategory', categoryId));
      await Promise.all(allPromises);
      this.$store.commit('v2/search/SET_ACTIVE_PROPERTY_TYPES', this.propertyTypes.slice());
    },
  },
};
</script>
