<template>
  <div class="search-sidebar--dropdown">
    <div
      v-b-toggle.collapseBuildingSize
      class="d-flex"
      style="justify-content: space-between;"
      @click="open"
    >
      <h3 class="filter--title">{{ $t('addListing.buildingSize') }}</h3>
      <i :class="!isOpened ? 'ion-chevron-down' : 'ion-chevron-up'"></i>
    </div>
    <b-collapse id="collapseBuildingSize">
      <div class="d-flex align-items-center">
        <div class="input--group">
          <cleave
            v-model="buildingSizeMin"
            :placeholder="$t('general.min')"
            :options="cleaveOptions"
            class="basic--input size--input"
            name="buildingSizeMin"
            :readonly="isLoading"
          ></cleave>
          <div class="append--right">
            m2
          </div>
        </div>
        <span class="separator">-</span>
        <div class="input--group">
          <cleave
            v-model="buildingSizeMax"
            :placeholder="$t('general.max')"
            :options="cleaveOptions"
            class="basic--input size--input"
            name="buildingSizeMax"
            :readonly="isLoading"
          ></cleave>
          <div class="append--right">
            m2
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import SearchMixin from '@/mixins/searchMixin';
import LodashMixin from '@/mixins/lodash';
import { mapState } from 'vuex';
const Cleave = () => import('vue-cleave-component');

export default {
  mixins: [SearchMixin, LodashMixin],
  components: {
    Cleave,
  },
  computed: {
    ...mapState({
      isLoading: state => state.v2.search.isLoading,
    }),
    buildingSizeMin: {
      get() {
        return this.$store.state.v2.search.buildingSizeMin;
      },
      set(val) {
        this.buildingSizeMinSetter(val);
      },
    },
    buildingSizeMax: {
      get() {
        return this.$store.state.v2.search.buildingSizeMax;
      },
      set(val) {
        this.buildingSizeMaxSetter(val);
      },
    },
  },
  data: () => ({
    cleaveOptions: {
      numeral: true,
      numeralDecimalMark: ',',
      delimiter: '.',
    },
    isOpened: false,
    fromPage: 'SEARCH',
    isMobileFilter: false,
  }),
  created() {
    this.buildingSizeMinSetter = this.debounce(this.buildingSizeMinSetter, 500);
    this.buildingSizeMaxSetter = this.debounce(this.buildingSizeMaxSetter, 500);
  },
  methods: {
    open() {
      this.isOpened = !this.isOpened;
    },
    onUpdateFilter() {},
  },
};
</script>
