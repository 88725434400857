<template>
  <section class="search--filters">
    <h2 style="display: none;">{{ $t('general.searchFilterTitle') }}</h2>
    <client-only>
      <div class="title--text">
        <div class="back--button" v-if="$mq === 'xs'" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        <div class="d-flex justify-space-between align-items-center flex-grow-1">
          <div>{{ $t('general.filter.filter') }}</div>
          <div class="clear--filter" v-if="$mq === 'xs'" @click="clearFilters()">
            {{ $t('general.filter.clearAll') }}
          </div>
        </div>
      </div>
      <div class="filter--footer" :class="{ active: isActive }" v-if="$mq === 'xs'">
        <button class="btn btn-primary" @click="applyFilters()">
          {{ $t('general.filter.apply') }}
        </button>
      </div>
    </client-only>
    <listing-type></listing-type>
    <category-dropdown v-show="searchType === 2"></category-dropdown>
    <!--    <project-partner v-show="listingType === 'S'"></project-partner>-->
    <sell-price v-show="listingType === 'S'"></sell-price>
    <rent-price v-show="listingType === 'R'"></rent-price>
    <rent-type v-show="listingType === 'R'"></rent-type>
    <tenure-dropdown v-show="searchType === 2"></tenure-dropdown>
    <building-size v-show="searchType === 2"></building-size>
    <pano-dropdown v-show="searchType === 2"></pano-dropdown>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import SellPrice from './partials/filters-sidebar/sell-price';
import RentPrice from './partials/filters-sidebar/rent-price';
import BuildingSize from './partials/filters-sidebar/building-size';
import RentType from './partials/filters-sidebar/rent-type';
import CategoryDropdown from './partials/filters-sidebar/category-dropdown';
import TenureDropdown from './partials/filters-sidebar/tenure-dropdown';
import PanoDropdown from './partials/filters-sidebar/pano-dropdown';
// import ProjectPartner from './partials/filters-sidebar/project-partner';
import ListingType from './partials/filters-sidebar/listing-type-dropdown';
export default {
  name: 'search-filters',
  components: {
    PanoDropdown,
    CategoryDropdown,
    TenureDropdown,
    RentType,
    BuildingSize,
    SellPrice,
    RentPrice,
    // ProjectPartner,
    ListingType,
  },
  computed: {
    ...mapState({
      listingType: state => state.v2.search.listingType,
      searchType: state => state.v2.search.searchType,
      isActive: state => state.v2.search.activeFilterSidebar,
    }),
  },
  methods: {
    close() {
      this.$store.commit('v2/search/SET_ACTIVE_FILTER_SIDEBAR', false);
    },
    clearFilters() {
      this.$store.commit('v2/search/CLEAR_FILTER');
    },
    applyFilters() {
      this.close();
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
  },
};
</script>
