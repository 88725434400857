<template>
  <div class="search-sidebar--dropdown">
    <div
      v-b-toggle.collapseTenure
      class="d-flex"
      style="justify-content: space-between;"
      @click="open"
    >
      <h3 class="filter--title">{{ $t('general.filter.tenure') }}</h3>
      <i :class="!isOpened ? 'ion-chevron-down' : 'ion-chevron-up'"></i>
    </div>
    <b-collapse id="collapseTenure">
      <div class="dropdown-item" v-for="(tenure, key) in tenureTypes" :key="`tenure${key}`">
        <div class="custom-control custom-radio">
          <input
            type="radio"
            :id="'tenure' + key"
            v-model="tenureType"
            :value="tenure.id"
            class="custom-control-input"
            :disabled="isLoading"
          />
          <label class="custom-control-label" :for="'tenure' + key">
            <span>{{ $t(tenure.name) }}</span>
          </label>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SearchMixin from '@/mixins/searchMixin';

export default {
  mixins: [SearchMixin],
  data: () => ({
    isOpened: false,
    fromPage: 'SEARCH',
    isMobileFilter: false,
  }),
  computed: {
    ...mapState({
      tenureTypes: state => state.v2.masters.tenureTypes,
      isLoading: state => state.v2.search.isLoading,
    }),
    tenureType: {
      get() {
        let tenureType = this.$store.state.v2.search.tenureType;
        return tenureType ? tenureType.id : null;
      },
      set(val) {
        this.onTenureClick(val);
      },
    },
  },
  methods: {
    async onTenureClick(tenureTypeId) {
      let tenureType = this.tenureTypes.find(function(item) {
        return item.id === tenureTypeId;
      });
      if (!tenureType) tenureType = this.tenureTypes[0];
      if (this.fromPage === 'HOME') {
        this.$store.commit('v2/search/SET_TENURE_TYPE', tenureType);
      } else {
        let query = await this.$store.dispatch('v2/search/setTenureType', {
          payload: tenureType,
          query: this.$route.query,
        });

        await this.updateRoute(query);
      }
    },
    open() {
      this.isOpened = !this.isOpened;
    },
    onUpdateFilter() {},
  },
};
</script>

<style lang="scss" scoped>
.filter--image {
  height: 20px;
}
</style>
