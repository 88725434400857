<template>
  <div class="card--listing" v-if="listing" @mousedown="onMouseDown" @mouseup="onMouseUp">
    <div class="card--listing-compare" v-show="!premier && showCompare && $mq !== 'xs'">
      <!--      <compare :listing="listing" ref="compare" />-->
    </div>
    <div class="favorite" @click="toggleShortlist">
      <img
        v-if="shortlisted"
        src="@/assets/img/icons/favorite-filled.svg"
        alt="Favorite"
        class="favorite--filled"
      />
      <img v-else src="@/assets/img/icons/favorite-white.svg" alt="Favorite Outline" />
    </div>
    <div class="top--part">
      <card-listing-image
        :linkDetail="listing.links ? listing.links.detail : '/'"
        :photos="listing.photos"
        :primaryPhoto="listing.primary_photo"
        :allowMultiImage="allowMultiImage"
        :imageAlt="listing.listing_title"
      ></card-listing-image>
      <router-link :to="listing.links ? listing.links.detail : '/'" style="text-decoration: none">
        <div v-show="premier" class="indicator premium">
          Premium Listing
        </div>
        <div class="icons">
          <img
            src="@/assets/img/icons/mask-1.svg"
            alt="Covid Protocol"
            class="icon"
            v-if="listing.covid_protocol_available"
          />
          <img
            src="@/assets/img/icons/360-degrees-1.svg"
            alt="360 Panorama"
            class="icon"
            v-if="listing.pano_embed_url"
          />
        </div>
      </router-link>
    </div>
    <router-link :to="listing.links ? listing.links.detail : '/'" style="text-decoration: none">
      <div class="bottom--part">
        <h3 class="name multiline-ellipsis-2">
          {{ listing.listing_title }}
        </h3>
        <div v-if="listing.view_to_offer">
          <div class="price--after">
            View To Offer
          </div>
        </div>
        <div v-if="listing.type == 'R' && !listing.view_to_offer">
          <div
            class="price--before"
            v-if="listing.discount_price != null || listing.discount_percentage != null"
          >
            {{ $n(listing.lowest_rent_price.price, 'currency', 'en-SG') }}
          </div>
          <div class="price--after">
            {{
              $n(
                getPriceAfterDiscount(
                  listing.discount_percentage,
                  listing.discount_price,
                  listing.lowest_rent_price.price,
                ).price_after_discount,
                'currency',
                'en-SG',
              )
            }}
            / {{ listing.lowest_rent_price.periode | lowerCase }}
          </div>
        </div>
        <div v-else-if="listing.type == 'S' && !listing.view_to_offer">
          <div
            class="price--before"
            v-if="listing.discount_price != null || listing.discount_percentage != null"
          >
            {{ $n(listing.sell_price, 'currency', 'en-SG') }}
          </div>
          <div class="price--after">
            {{
              $n(
                getPriceAfterDiscount(
                  listing.discount_percentage,
                  listing.discount_price,
                  listing.sell_price,
                ).price_after_discount,
                'currency',
                'en-SG',
              )
            }}
          </div>
        </div>
        <div class="label--container">
          <div class="label mr-2">
            {{ listing.type === 'R' ? $t('general.rent') : $t('general.sell') }}
          </div>
          <div class="label" v-if="listing.property">{{ listing.property.property_type_name }}</div>
          <div class="label label--occupied" v-if="listing.occupied">
            {{ listing.type === 'R' ? $t('general.rented') : $t('general.sold') }}
          </div>
        </div>
        <div class="location" v-if="listing.property">
          <img src="@/assets/img/icons/pin-1.svg" alt="Location Pin" />
          <span>{{ listing.property.location.city_name }}</span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import CardListingImage from '@/components/utils/partials/card-listing-image';
// const Compare = () => import('@/components/utils/partials/compare');
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

export default {
  mixins: [HelperMixin],
  components: {
    CardListingImage,
    // Compare,
  },
  props: {
    listing: {
      default: null,
      require: true,
    },
    premier: {
      default: false,
    },
    showCompare: {
      default: true,
    },
    allowMultiImage: {
      default: false,
    },
  },
  data: () => ({
    debounceCompare: null,
  }),
  computed: {
    ...mapState({
      shortlists: state => state.v2.search.shortlists,
      loggedIn: state => state.global.loggedIn,
    }),
    shortlisted() {
      if (this.shortlists && this.shortlists.length === 0) {
        return false;
      } else {
        return this.shortlists.indexOf(this.listing.id) !== -1;
      }
    },
  },
  methods: {
    async toggleShortlist() {
      if (this.loggedIn) {
        let response = await this.$store.dispatch(
          'v2/listing/addOrRemoveShortlist',
          this.listing.id,
        );
        await this.$store.commit('v2/search/set_shortlists', response);
      } else {
        this.$modal.show('modal-login');
      }
    },
    onMouseDown() {
      // Hold down to add listing to compare still not working
      // if (this.$mq === 'xs') {
      //   this.debounceCompare = this.debounce(this.addToCompare, 50);
      // }
    },
    onMouseUp() {
      // Hold down to add listing to compare still not working
      // if (this.$mq === 'xs') {
      //   this.debounceCompare = null;
      // }
    },
    addToCompare(listing) {
      this.$store.commit('v2/listing/set_compare_listing', listing);
      this.$store.commit('v2/listing/set_compare_show', true);
    },
  },
  filters: {
    lowerCase(e) {
      return e.toLowerCase();
    },
  },
};
</script>
