<template>
  <section class="search--content">
    <client-only>
      <div class="top--part">
        <h2 class="title--text">
          <span class="bold">
            {{ metaListing ? metaListing.total : '' }}
          </span>
          {{ propertyTypeString }}
          {{ listingType === 'R' ? $t('general.availableR') : $t('general.availableS') }}
        </h2>
        <div class="sort">
          <div class="filter--button" @click="openFilterSidebar()" v-if="$mq === 'xs'">
            <img src="@/assets/img/icons/filter.svg" />
            <div>{{ $t('general.filter.filterShort') }}</div>
          </div>
          <v-menu
            offset-y
            content-class="filter--popup-container"
            :close-on-content-click="false"
            v-model="isOpenPopup"
            v-else
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="filter--button" v-bind="attrs" v-on="on">
                <img src="@/assets/img/icons/filter.svg" />
                <div>{{ $t('general.filter.filterShort') }}</div>
              </div>
            </template>
            <search-filters></search-filters>
            <button class="btn btn-primary" style="float: right;" @click="applyFilters">
              {{ $t('general.filter.applyFilter') }}
            </button>
          </v-menu>
          <v-switch
            class="map--switch"
            v-model="showMap"
            :label="$t('general.showMap')"
            inset
            hide-details
          ></v-switch>
          <div class="sort--text">{{ $t('general.filter.sort') }}</div>
          <v-select
            :value="activeSortBy"
            @input="inputSortByHandler"
            :items="sortBys"
            :item-text="sortByLabel"
            item-value="id"
            class="dropdown basic--dropdown"
            solo
            hide-details
          ></v-select>
        </div>
      </div>
    </client-only>
    <div class="bottom--part">
      <section-loading :show="isLoading" />
      <pagination
        v-if="searchType === 2 && metaListing"
        :push-state="false"
        :meta="metaListing"
        @changePage="getSearch"
        :maxVisibleButtons="5"
      />
      <pagination
        v-else-if="searchType === 1 && metaProject"
        :push-state="false"
        :meta="metaProject"
        @changePage="getSearch"
        :maxVisibleButtons="5"
      />
      <div class="premier--listing-wrapper" v-if="premierListings && premierListings.length > 0">
        <div v-for="(listing, index) in premierListings" :key="index" class="listing--item">
          <card-listing :listing="listing" :premier="true" :allowMultiImage="true"></card-listing>
        </div>
      </div>
      <div class="card--listing-wrapper" :class="{ 'closed--map': !showMap }">
        <div v-for="listing in listings" :key="listing.uuid" class="listing--item">
          <card-listing :listing="listing" :allowMultiImage="true"></card-listing>
        </div>
        <div v-for="project in projects" :key="project.uuid" class="listing--item">
          <card-project-partner
            :projectPartner="project"
            :allowMultiImage="true"
          ></card-project-partner>
        </div>
      </div>
      <pagination
        v-if="searchType === 2 && metaListing"
        :push-state="false"
        :meta="metaListing"
        @changePage="getSearch"
        :maxVisibleButtons="5"
      />
      <pagination
        v-else-if="searchType === 1 && metaProject"
        :push-state="false"
        :meta="metaProject"
        @changePage="getSearch"
        :maxVisibleButtons="5"
      />
    </div>
  </section>
</template>

<script>
import CardListing from '@/components/utils/card-listing';
const CardProjectPartner = () => import('@/components/utils/card-project-partner');
const Pagination = () => import('@/components/utils/fractal-pagination.vue');
const SectionLoading = () => import('@/components/content-loading/section-loading');
import SearchFilters from '@/components/search/filters';
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'search-content',
  components: {
    CardListing,
    CardProjectPartner,
    Pagination,
    SectionLoading,
    SearchFilters,
  },
  data: () => ({
    isOpenPopup: null,
  }),
  computed: {
    ...mapState({
      sortBys: state => state.v2.search.sortByOptions,
      metaListing: state => state.v2.search.meta,
      listings: state => state.v2.search.listings,
      metaProject: state => state.v2.search.metaProject,
      projects: state => state.v2.search.projects,
      premierListings: state => state.v2.search.premierListings,
      listingType: state => state.v2.search.listingType,
      searchType: state => state.v2.search.searchType,
      isLoading: state => state.v2.search.isLoading,
    }),
    ...mapGetters({
      activeSortBy: 'v2/search/activeSortBy',
      propertyTypeString: 'v2/masters/selectedPropertyTypesString',
    }),
    showMap: {
      get() {
        return this.$store.state.global.showMap;
      },
      set(val) {
        this.$store.commit('global/SET_SHOW_MAP', val);
      },
    },
  },
  watch: {
    isOpenPopup(val) {
      console.log('IS OPEN FILTER POPUP: ', val);
      this.$store.commit('v2/search/SET_ACTIVE_FILTER_SIDEBAR', val);
    },
  },
  methods: {
    sortByLabel(value) {
      return this.$i18n.t(`general.filter.${value.name}`);
    },
    inputSortByHandler(value) {
      let sortBy = this.sortBys.find(function(item) {
        return item.id === value;
      });
      this.$store.commit('v2/search/SET_SORT_BY', sortBy);
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
    getSearch(page) {
      this.$store.commit('v2/search/SET_PAGE', page ? page : 1);
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
    openFilterSidebar() {
      this.$store.commit('v2/search/SET_ACTIVE_FILTER_SIDEBAR', true);
    },
    applyFilters() {
      this.isOpenPopup = false;
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
  },
};
</script>
