<template>
  <div class="search-sidebar--dropdown">
    <div
      v-b-toggle.collapseSellPrice
      class="d-flex"
      style="justify-content: space-between;"
      @click="open"
    >
      <h3 class="filter--title">{{ $t('general.approximatePrice') }}</h3>
      <i :class="!isOpened ? 'ion-chevron-down' : 'ion-chevron-up'"></i>
    </div>
    <b-collapse id="collapseSellPrice">
      <div class="d-flex align-items-center">
        <cleave
          v-model="rentPriceMin"
          :placeholder="$t('general.min')"
          :options="cleaveOptionsPrice"
          class="basic--input"
          name="rentPriceMin"
          :readonly="isLoading"
        ></cleave>
        <span class="separator">-</span>
        <cleave
          v-model="rentPriceMax"
          :placeholder="$t('general.max')"
          :options="cleaveOptionsPrice"
          class="basic--input"
          name="rentPriceMax"
          :readonly="isLoading"
        ></cleave>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import SearchMixin from '@/mixins/searchMixin';
import LodashMixin from '@/mixins/lodash';
import { mapState } from 'vuex';
const Cleave = () => import('vue-cleave-component');

export default {
  mixins: [SearchMixin, LodashMixin],
  components: {
    Cleave,
  },
  computed: {
    ...mapState({
      isLoading: state => state.v2.search.isLoading,
    }),
    rentPriceMin: {
      get() {
        return this.$store.state.v2.search.rentPriceMin;
      },
      set(val) {
        this.rentPriceMinSetter(val);
      },
    },
    rentPriceMax: {
      get() {
        return this.$store.state.v2.search.rentPriceMax;
      },
      set(val) {
        this.rentPriceMaxSetter(val);
      },
    },
  },
  data: () => ({
    cleaveOptionsPrice: {
      numeral: true,
      numeralDecimalMark: ',',
      delimiter: '.',
      prefix: 'S$ ',
    },
    isOpened: false,
    fromPage: 'SEARCH',
    isMobileFilter: false,
  }),
  created() {
    this.rentPriceMinSetter = this.debounce(this.rentPriceMinSetter, 500);
    this.rentPriceMaxSetter = this.debounce(this.rentPriceMaxSetter, 500);
  },
  methods: {
    open() {
      this.isOpened = !this.isOpened;
    },
    onUpdateFilter() {},
  },
};
</script>
