<template>
  <form @submit.prevent="goToSearch">
    <div class="search--listing-wrapper">
      <client-only>
        <vue-bootstrap-typeahead
          ref="searchTypehead"
          :data="suggestions"
          v-model="currentKeyword"
          inputClass="basic--input"
          class="input--wrapper"
          :serializer="s => s.name"
          :placeholder="$t('home.searchBarPlaceholder')"
          @hit="selectedSuggestion"
          @blur="handleBlur"
          @click="handleClick"
          @input="handleInput"
        >
          <template slot="suggestion" slot-scope="{ data, htmlText }">
            <div class="d-flex align-items-center">
              <i
                class="ion-earth"
                v-if="data.category === 'city' || data.category === 'province'"
              ></i>
              <i class="icon icon--building icon--sm" v-if="data.category === 'listing'"></i>
              <span class="ml-4" v-html="htmlText"></span>
            </div>
          </template>
        </vue-bootstrap-typeahead>
      </client-only>
      <div v-show="currentKeyword" class="reset--search">
        <button type="button" @click="resetSearch">
          <span class="ion-close"></span>
          <span class="reset--text">Reset Search</span>
        </button>
        <div v-show="isLoading" class="tab_disabled"></div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import VueBootstrapTypeahead from '@/components/utils/vue-bootstrap-typeahead/VueBootstrapTypeahead';
import LodashMixin from '@/mixins/lodash';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'search-listing',
  mixins: [LodashMixin, HelperMixin],
  components: {
    VueBootstrapTypeahead,
  },
  data: () => ({
    suggestions: [],
    getSuggestionLoading: false,
  }),
  computed: {
    ...mapState({
      isLoading: state => state.v2.search.isLoading,
      listingType: state => state.v2.search.listingType,
      searchType: state => state.v2.search.searchType,
      searchTerm: state => state.v2.search.searchTerm,
    }),
    searchTermId: {
      get() {
        return this.$store.state.v2.search.searchTermId;
      },
      set(val) {
        this.$store.commit('v2/search/SET_SEARCH_TERM_ID', val);
      },
    },
    searchTermCategory: {
      get() {
        return this.$store.state.v2.search.searchTermCategory;
      },
      set(val) {
        this.$store.commit('v2/search/SET_SEARCH_TERM_CATEGORY', val);
      },
    },
    currentKeyword: {
      get() {
        return this.$store.state.v2.search.currentKeyword;
      },
      set(value) {
        this.$store.commit('v2/search/SET_CURRENT_KEYWORD', value);
        this.$store.commit('v2/search/SET_SEARCH_TERM', value);
      },
    },
  },
  watch: {
    currentKeyword() {
      this.debouncedQuery();
      this.replaceInputValue();
    },
  },
  mounted() {
    this.debouncedQuery = this.debounce(this.getSuggestions, 500);
    this.currentKeyword = this.searchTerm;
    this.replaceInputValue();
  },
  methods: {
    handleBlur() {
      this.$store.commit('v2/search/SET_FOCUS_INPUT_SEARCH', false);
      this.$store.commit('v2/search/SET_COVER_SEARCH', true);
    },
    handleClick() {
      if (this.$mq !== 'xs') this.showMenu();
    },
    showMenu() {
      this.$store.commit('v2/search/SET_OPEN_CATEGORIES', true);
    },
    handleInput() {
      this.searchTermId = null;
      this.searchTermCategory = null;
    },
    async getSuggestions() {
      try {
        this.getSuggestionLoading = true;
        let response = await this.$store.dispatch('v2/search/getSuggestions', {
          query: this.currentKeyword,
          listingType: this.listingType,
          searchType: this.searchType,
        });
        if (response.type === 'success') {
          let suggestions = [];
          for (let suggestion of response.data) {
            suggestion.name = this.decodeDistrictName(suggestion.name);
            suggestions.push(suggestion);
          }
          this.suggestions = suggestions;
        }
      } finally {
        this.getSuggestionLoading = false;
      }
    },
    selectedSuggestion(data) {
      this.currentKeyword = data.name;
      this.$store.commit('v2/search/SET_SEARCH_TERM', data.name);
      this.$store.commit('v2/search/SET_SEARCH_TERM_ID', data.id);
      this.$store.commit('v2/search/SET_SEARCH_TERM_CATEGORY', data.category);
      this.goToSearch();
    },
    replaceInputValue() {
      let self = this;
      if (self.$refs.searchTypehead !== undefined) {
        self.$refs.searchTypehead.inputValue = this.searchTerm;
      } else {
        setTimeout(function() {
          self.replaceInputValue();
        }, 500);
      }
    },
    isAllowSearch() {
      return (
        !this.currentKeyword ||
        this.searchType !== 1 ||
        (this.searchTermId !== null && this.searchTermCategory !== null)
      );
    },
    resetSearch() {
      if (!this.isLoading) {
        this.currentKeyword = null;
        this.$store.commit('v2/search/SET_SEARCH_TERM', null);
        this.$store.commit('v2/search/SET_SEARCH_TERM_ID', null);
        this.$store.commit('v2/search/SET_SEARCH_TERM_CATEGORY', null);
        // this.$store.commit('v2/search/SET_DISTRICT_ID', null);
        this.$store.commit('v2/search/SET_ACTIVE_DISTRICTS', []);
        this.replaceInputValue();
        this.goToSearch();
      }
    },
    goToSearch() {
      this.$store.commit('v2/search/SET_PAGE', 1);
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
  },
};
</script>

<style scoped></style>
