<template>
  <section class="search--header">
    <client-only>
      <div class="search--header-content">
        <!--        <div class="dropdown&#45;&#45;wrapper">-->
        <!--          <v-select-->
        <!--            :value="listingType"-->
        <!--            @input="inputFilterHandler"-->
        <!--            :items="listingTypes"-->
        <!--            :hint="`${$t('addListing.listingType')} ${$t(`general.filter.${listingType}`)}`"-->
        <!--            class="basic&#45;&#45;dropdown"-->
        <!--            persistent-hint-->
        <!--            single-line-->
        <!--          >-->
        <!--            <template slot="selection" slot-scope="{ item }">-->
        <!--              {{ $t(`general.filter.${item}`) }}-->
        <!--            </template>-->
        <!--            <template slot="item" slot-scope="{ item }">-->
        <!--              {{ $t(`general.filter.${item}`) }}-->
        <!--            </template>-->
        <!--          </v-select>-->
        <!--        </div>-->
        <!--        <div class="dropdown&#45;&#45;wrapper">-->
        <!--          <v-select-->
        <!--            v-model="activePropertyCategory"-->
        <!--            @input="inputFilterHandler"-->
        <!--            :items="categories"-->
        <!--            :item-text="categoryLabel"-->
        <!--            item-value="id"-->
        <!--            :label="$t('general.allCategories')"-->
        <!--            class="basic&#45;&#45;dropdown black&#45;&#45;label"-->
        <!--            hide-details-->
        <!--            single-line-->
        <!--            solo-->
        <!--            return-object-->
        <!--          >-->
        <!--            <template slot="selection" slot-scope="{ item }">-->
        <!--              {{ $t(`general.${item.name}`) }}-->
        <!--            </template>-->
        <!--            <template slot="item" slot-scope="{ item }">-->
        <!--              {{ $t(`general.${item.name}`) }}-->
        <!--            </template>-->
        <!--          </v-select>-->
        <!--        </div>-->
        <div class="listing--type-tab">
          <v-tabs
            center-active
            show-arrows
            v-model="activeListingTypeTab"
            v-if="listingTypes && listingTypes.length > 0"
          >
            <v-tab
              :href="`#tab-listing-type-${listingType}`"
              v-for="listingType in listingTypes"
              :key="`listing-type-${listingType}`"
              @click="inputListingTypeHandler(listingType)"
            >
              <div class="d-flex justify-content-center">
                <img src="@/assets/img/icons/paper.png" v-if="listingType === 'R'" />
                <img src="@/assets/img/icons/tag.png" v-else />
                {{ $t(`general.filter.${listingType}`) }}
              </div>
            </v-tab>
          </v-tabs>
        </div>
        <div class="dropdown--wrapper" v-if="$mq !== 'xs'">
          <v-select
            v-model="activePropertyTypes"
            @input="inputFilterHandler"
            :items="propertyTypes"
            :item-text="propertyTypeLabel"
            item-value="id"
            :label="$t('general.allPropertyTypes')"
            :menu-props="{
              'content-class': 'property-type--popup-container',
            }"
            class="basic--dropdown black--label"
            hide-details
            single-line
            solo
            multiple
            return-object
          >
            <template v-slot:selection="{ item, index }">
              <div v-if="activePropertyTypes && index === 0">
                <span v-if="activePropertyTypes.length === 0">
                  {{ $t('general.allPropertyTypes') }}
                </span>
                <span v-else>
                  {{
                    `${activePropertyTypes.length} ${$t('general.search.propertyTypesSelected')}`
                  }}
                </span>
              </div>
            </template>
            <template v-slot:prepend-item>
              <div class="d-flex justify-content-between align-items-center px-4 mb-4">
                <div class="menu--title">{{ $t('general.propertyType') }}</div>
                <button class="btn small--btn btn-primary" @click="forceSearch">
                  {{ $t('general.filter.applyFilter') }}
                </button>
              </div>
              <div class="d-flex switch flex-wrap pl-4">
                <div
                  class="btn small--btn mr-2 mb-2"
                  :class="
                    activePropertyCategory && activePropertyCategory.id === category.id
                      ? 'btn-primary'
                      : 'btn-primary--outline'
                  "
                  @click="chooseCategory(category)"
                  v-for="(category, index) in categories"
                  :key="`category-${index}`"
                >
                  {{ category ? $t(`general.${category.name}`) : '' }}
                </div>
              </div>
              <v-divider class="mt-2"></v-divider>
              <div class="d-flex switch flex-wrap pl-4">
                <div
                  class="btn small--btn mr-2 mb-2"
                  :class="
                    selectedPropertyTypeCategory === null ? 'btn-primary' : 'btn-primary--outline'
                  "
                  @click="choosePropertyTypeCategory(null)"
                >
                  {{ $t('general.all') }}
                </div>
                <div
                  class="btn small--btn mr-2 mb-2"
                  :class="
                    selectedPropertyTypeCategory &&
                    selectedPropertyTypeCategory.id === propertyTypeCategory.id
                      ? 'btn-primary'
                      : 'btn-primary--outline'
                  "
                  @click="choosePropertyTypeCategory(propertyTypeCategory)"
                  v-for="(propertyTypeCategory, index) in propertyTypeCategories"
                  :key="`property-type-category-${index}`"
                >
                  {{ propertyTypeCategory ? propertyTypeCategory.name : '' }}
                </div>
              </div>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </div>
        <div class="dropdown--wrapper" v-if="$mq !== 'xs'">
          <v-select
            v-model="activeDistricts"
            @input="inputFilterHandler"
            :items="districts"
            :item-text="districtLabel"
            item-value="id"
            :label="$t('general.allDistricts')"
            :menu-props="{
              'content-class': 'property-type--popup-container',
            }"
            class="basic--dropdown black--label"
            hide-details
            persistent-hint
            single-line
            solo
            multiple
            return-object
          >
            <template v-slot:selection="{ item, index }">
              <div v-if="activeDistricts && index === 0">
                <span v-if="activeDistricts.length === 0">
                  {{ $t('general.allDistricts') }}
                </span>
                <span v-else>
                  {{ `${activeDistricts.length} ${$t('general.search.districtsSelected')}` }}
                </span>
              </div>
            </template>
            <template v-slot:prepend-item>
              <div class="d-flex justify-content-between align-items-center px-4 mb-2">
                <div class="menu--title">{{ $t('general.district') }}</div>
                <button class="btn btn-primary" @click="forceSearch">
                  {{ $t('general.filter.applyFilter') }}
                </button>
              </div>
              <v-list-item
                ripple
                @mousedown.prevent
                @click="selectAllDistricts"
                :class="{ 'v-list-item--active': activeDistricts.length > 0 }"
              >
                <v-list-item-action>
                  <v-icon>
                    {{ districtIcon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('general.allDistricts') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </div>
        <div class="search--button" @click="forceSearch" v-if="$mq !== 'xs'">
          <img src="@/assets/img/icons/search.svg" alt="Search" />
        </div>
      </div>
    </client-only>
    <div class="bottom--row">
      <search-listing class="bottom--row-search" />
      <v-switch
        class="map--switch"
        v-model="showMap"
        :label="$t('general.showMap')"
        inset
        hide-details
      ></v-switch>
    </div>
  </section>
</template>

<script>
import SearchListing from '@/components/search/partials/search-listing';
import { mapState, mapGetters } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'search-header',
  mixins: [HelperMixin],
  components: { SearchListing },
  data: () => ({
    activeListingTypeTab: 0,
  }),
  computed: {
    ...mapState({
      listingType: state => state.v2.search.listingType,
      listingTypes: state => state.global.listingTypes,
      // propertyTypes: state => state.v2.masters.propertyTypes,
      propertyTypeCategories: state => state.v2.masters.propertyTypeCategories,
      categories: state => state.v2.search.categories,
      districts: state => state.v2.masters.districts,
    }),
    ...mapGetters({
      propertyTypes: 'v2/masters/propertyTypesByCategory',
    }),
    listingType: {
      get() {
        return this.$store.state.v2.search.listingType;
      },
      set(val) {
        this.$store.commit('v2/search/SET_LISTING_TYPE', val);
      },
    },
    activePropertyCategory: {
      get() {
        return this.$store.state.v2.search.category;
      },
      set(val) {
        this.$store.commit('v2/search/SET_CATEGORY', val);
        this.onPropertyTypeCategoryChange(val);
      },
    },
    selectedPropertyTypeCategory: {
      get() {
        return this.$store.state.v2.search.selectedPropertyTypeCategory;
      },
      set(val) {
        this.$store.commit('v2/search/SET_SELECTED_PROPERTY_TYPE_CATEGORY', val);
      },
    },
    activePropertyTypes: {
      get() {
        return this.$store.state.v2.search.activePropertyTypes;
      },
      set(val) {
        this.$store.commit('v2/search/SET_ACTIVE_PROPERTY_TYPES', val);
      },
    },
    activeDistricts: {
      get() {
        return this.$store.state.v2.search.activeDistricts;
      },
      set(val) {
        this.$store.commit('v2/search/SET_ACTIVE_DISTRICTS', val);
        this.onDistrictChange(val);
      },
    },
    showMap: {
      get() {
        return this.$store.state.global.showMap;
      },
      set(val) {
        this.$store.commit('global/SET_SHOW_MAP', val);
      },
    },
    districtIcon() {
      if (this.activeDistricts.length === this.districts.length) return 'mdi-checkbox-marked';
      else if (this.activeDistricts.length > 0) return 'mdi-minus-box';
      else return 'mdi-checkbox-blank-outline';
    },
  },
  watch: {
    async listingType(value) {
      this.calculateListingTypeTab(value);
    },
  },
  mounted() {
    this.calculateListingTypeTab(this.listingType);
  },
  methods: {
    categoryLabel(value) {
      return this.$i18n.t(`general.${value.name}`);
    },
    propertyTypeLabel(value) {
      return value.name === 'general.allPropertyTypes' ? this.$i18n.t(`${value.name}`) : value.name;
    },
    districtLabel(value) {
      return value.name === 'general.all'
        ? this.$i18n.t('general.allDistricts')
        : `(${value.name}) - ${this.decodeDistrictName(value.general_location)}`;
      // return this.activeDistricts.length === 0
      //   ? this.$i18n.t('general.allDistricts')
      //   : `${this.activeDistricts.length} ${this.$i18n.t('general.search.districtsSelected')}`;
    },
    inputFilterHandler() {
      this.$store.commit('v2/search/SET_PAGE', 1);
    },
    async onPropertyTypeCategoryChange(val) {
      let allPromises = [];
      let categoryId = val ? val.id : null;
      allPromises.push(this.$store.dispatch('v2/masters/getPropertyTypeCategories', categoryId));
      allPromises.push(this.$store.dispatch('v2/masters/getPropertyTypesByCategory', categoryId));
      await Promise.all(allPromises);
      this.choosePropertyTypeCategory(null);
    },
    chooseCategory(category) {
      this.inputFilterHandler();
      this.activePropertyCategory = category;
    },
    choosePropertyTypeCategory(propertyTypeCategory) {
      this.selectedPropertyTypeCategory = propertyTypeCategory;
      this.activePropertyTypes = this.propertyTypes.slice();
    },
    selectAllDistricts() {
      this.$nextTick(() => {
        if (this.activeDistricts.length > 0) {
          this.activeDistricts = [];
        } else {
          this.activeDistricts = this.districts.slice();
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    onDistrictChange(val) {
      if (this.$store.state.v2.search.searchTermCategory === 'district') {
        this.$store.commit('v2/search/SET_SEARCH_TERM', null);
        this.$store.commit('v2/search/SET_SEARCH_TERM_ID', null);
        this.$store.commit('v2/search/SET_SEARCH_TERM_CATEGORY', null);
      }
    },
    async inputListingTypeHandler(value) {
      this.$store.commit('v2/search/SET_LISTING_TYPE', value);
      this.$store.commit('v2/search/SET_PAGE', 1);
      await this.onPropertyTypeCategoryChange(this.activePropertyCategory.id);
      try {
        await this.$store.dispatch('v2/search/goToSearchPage', {
          router: this.$router,
          params: this.$route.params,
        });
      } catch (e) {
        console.log('ERROR inputListingTypeHandler: ', e);
      }
    },
    calculateListingTypeTab(value) {
      this.activeListingTypeTab = 'tab-listing-type-' + value;
    },
    async forceSearch() {
      this.$store
        .dispatch('v2/search/goToSearchPage', {
          router: this.$router,
          params: this.$route.params,
        })
        // Catch error route karena routing ke url yang sama
        .catch(async () => {
          console.log('SAME ROUTE, FORCING SEARCH!');
          this.$store.commit('global/SET_LOADING', true);
          await this.$store.dispatch('v2/search/getSearchListing', {
            query: this.$route.query,
            params: this.$route.params,
          });
          this.$store.commit('global/SET_LOADING', false);
        });
    },
  },
};
</script>
