export default {
  data: () => ({
    isLoadingSearch: false,
  }),
  computed: {
    rentPeriod: {
      get() {
        return this.$store.state.v2.search.rentPeriod;
      },
      async set(val) {
        if (this.fromPage === 'HOME' || this.isMobileFilter) {
          this.$store.commit('v2/search/SET_RENT_PERIOD', val);
        } else {
          let query = await this.$store.dispatch('v2/search/setRentPeriod', {
            payload: val,
            query: this.$route.query,
          });

          await this.updateRoute(query);
        }
      },
    },
    rentTypeId: {
      get() {
        return this.$store.state.v2.search.rentTypeId;
      },
      async set(val) {
        if (this.fromPage === 'HOME' || this.isMobileFilter) {
          this.$store.commit('v2/search/SET_RENT_TYPE_ID', val);
        } else {
          let query = await this.$store.dispatch('v2/search/setRentTypeId', {
            payload: val,
            query: this.$route.query,
          });

          await this.updateRoute(query);
        }
      },
    },
    rangePrice: {
      get() {
        return this.$store.state.v2.search.rangePrice;
      },
      async set(val) {
        if (this.fromPage === 'HOME' || this.isMobileFilter) {
          this.$store.commit('v2/search/SET_RANGE_PRICE', val);
        } else {
          let query = await this.$store.dispatch('v2/search/setRangePrice', {
            payload: val,
            query: this.$route.query,
          });

          await this.updateRoute(query);
        }
      },
    },
    rangePriceName: {
      get() {
        return this.$store.state.v2.search.rangePriceName;
      },
      set(val) {
        this.$store.commit('v2/search/SET_RANGE_PRICE_NAME', val);
      },
    },
    projectStatus: {
      get() {
        return this.$store.state.v2.search.projectStatus;
      },
      set(val) {
        this.$store.commit('v2/search/SET_PROJECT_STATUS', val);
      },
    },
    category: {
      get() {
        return this.$store.state.v2.search.category;
      },
      set(val) {
        this.$store.commit('v2/search/SET_CATEGORY', val);
      },
    },
    route: {
      get() {
        return this.$store.state.route;
      },
      // eslint-disable-next-line no-unused-vars
      set(val) {},
    },
    searchTypeItem: {
      get() {
        return this.$store.state.v2.search.searchType;
      },
      async set(val) {
        if (this.fromPage === 'HOME' || this.isMobileFilter) {
          this.$store.commit('v2/search/SET_SEARCH_TYPE', val);
        } else {
          let query = await this.$store.dispatch('v2/search/setSearchType', {
            payload: val,
            query: this.$route.query,
          });

          await this.updateRoute(query);
        }
      },
    },
  },
  methods: {
    async buildingSizeMinSetter(value) {
      this.$store.commit('v2/search/SET_BUILDING_SIZE_MIN', value);
      if (this.fromPage !== 'HOME' && !this.isMobileFilter) {
        await this.onBuildingSizeChange();
      }
    },
    async buildingSizeMaxSetter(value) {
      this.$store.commit('v2/search/SET_BUILDING_SIZE_MAX', value);
      if (this.fromPage !== 'HOME' && !this.isMobileFilter) {
        await this.onBuildingSizeChange();
      }
    },
    async onBuildingSizeChange() {
      let buildingSizeMin = this.buildingSizeMin;
      let buildingSizeMax = this.buildingSizeMax;

      if (buildingSizeMin && buildingSizeMax && Number(buildingSizeMax) > Number(buildingSizeMin)) {
        if (this.formType !== 'home') {
          let query = await this.$store.dispatch('v2/search/onBuildingSizeChange', {
            query: this.$route.query,
          });

          await this.updateRoute(query);
        }
      }
    },
    async sellPriceMinSetter(value) {
      this.$store.commit('v2/search/SET_SELL_PRICE_MIN', value);
      if (this.fromPage !== 'HOME' && !this.isMobileFilter) {
        await this.onSellPriceChange();
      }
    },
    async sellPriceMaxSetter(value) {
      this.$store.commit('v2/search/SET_SELL_PRICE_MAX', value);
      if (this.fromPage !== 'HOME' && !this.isMobileFilter) {
        await this.onSellPriceChange();
      }
    },
    async onSellPriceChange() {
      let sellPriceMin = this.sellPriceMin ? this.sellPriceMin.replace('S$', '').trim() : '';
      let sellPriceMax = this.sellPriceMax ? this.sellPriceMax.replace('S$', '').trim() : '';

      if (sellPriceMin && sellPriceMax && Number(sellPriceMax) > Number(sellPriceMin)) {
        if (this.formType !== 'home') {
          let query = await this.$store.dispatch('v2/search/onSellPriceChange', {
            query: this.$route.query,
          });

          await this.updateRoute(query);
        }
      }
    },
    async rentPriceMinSetter(value) {
      this.$store.commit('v2/search/SET_RENT_PRICE_MIN', value);
      if (this.fromPage !== 'HOME' && !this.isMobileFilter) {
        await this.onRentPriceChange();
      }
    },
    async rentPriceMaxSetter(value) {
      this.$store.commit('v2/search/SET_RENT_PRICE_MAX', value);
      if (this.fromPage !== 'HOME' && !this.isMobileFilter) {
        await this.onRentPriceChange();
      }
    },
    async onRentPriceChange() {
      let rentPriceMin = this.rentPriceMin ? this.rentPriceMin.replace('S$', '').trim() : '';
      let rentPriceMax = this.rentPriceMax ? this.rentPriceMax.replace('S$', '').trim() : '';

      if (rentPriceMin && rentPriceMax && Number(rentPriceMax) > Number(rentPriceMin)) {
        if (this.formType !== 'home') {
          let query = await this.$store.dispatch('v2/search/onRentPriceChange', {
            query: this.$route.query,
          });

          await this.updateRoute(query);
        }
      }
    },
    async updateRoute(query) {
      // Check apakah ganti filter-nya dari sidebar atau bukan, kalau dari sidebar jangan langsung redirect halaman
      if (query && !this.$store.state.v2.search.activeFilterSidebar) {
        try {
          this.$store.commit('v2/search/SET_IS_LOADING', true);
          if (this.$route.name === 'search') {
            await this.$store.dispatch('v2/search/goToSearchPage', {
              router: this.$router,
              query: query,
              params: this.$route.params,
            });
          } else {
            await this.$router.push({ query: query }).catch(async e => {
              console.log('ERROR updateRoute (router.push) in searchMixin.js: ', e);
            });
          }
        } catch (e) {
          console.log('ERROR updateRoute in searchMixin.js: ', e);
        } finally {
          this.$store.commit('v2/search/SET_IS_LOADING', false);
        }
      }
    },
    // async getSearchListing(route) {
    //   if (!this.isLoadingSearch) {
    //     this.isLoadingSearch = true;
    //     console.log('Panggil get search listing dari searchMixin.js dengan isi route: ', route);
    //
    //     // if (self.$route.params.listingPartnerUrl)
    //     //   query = Object.assign({}, query, {
    //     //     partner_name: self.$route.params.listingPartnerUrl,
    //     //   });
    //     // if (self.$route.params.userUuid)
    //     //   query = Object.assign({}, query, { user_uuid: self.$route.params.userUuid });
    //
    //     await this.$store.commit('v2/search/set_listings', { data: [], meta: null });
    //     this.$store.commit('global/SET_LOADING', true);
    //
    //     let query = route.query;
    //     if (route.params && route.params.userUuid) {
    //       query = Object.assign({}, query, { user_uuid: route.params.userUuid });
    //     }
    //
    //     await Promise.all([
    //       this.$store.dispatch('v2/search/getSearchListing', query),
    //       this.$store.dispatch('v2/search/getGeoJsonListings', query),
    //     ]);
    //     this.$store.commit('global/SET_LOADING', false);
    //     this.isLoadingSearch = false;
    //   }
    // },
    // async getSearchListingPartner(isInit, route) {
    //   if (!this.isLoadingSearch) {
    //     this.isLoadingSearch = true;
    //     console.log(
    //       'Panggil get search listing partner dari searchMixin.js dengan isi route: ',
    //       route,
    //     );
    //
    //     for (let index = 0; index < this.categories.length; index++) {
    //       if (index === 0) {
    //         this.categories[index].isActive = true;
    //         this.category = this.categories[index];
    //       } else {
    //         this.categories[index].isActive = false;
    //       }
    //     }
    //
    //     await this.$store.commit('v2/search/set_listings', { data: [], meta: null });
    //     await this.$store.commit('global/SET_LOADING', true);
    //
    //     let query = JSON.parse(JSON.stringify(route.query));
    //     query = Object.assign({}, query, {
    //       partner_name: this.$route.params.listingPartnerUrl,
    //       listing_type: isInit ? this.listingTypePriority : this.listingType,
    //       paginate: 12,
    //     });
    //     await this.$store.dispatch('v2/search/getSearchListingPartner', query);
    //     this.$store.commit('global/SET_LOADING', false);
    //     this.isLoadingSearch = false;
    //   }
    // },
  },
  // watch: {
  //   route: function(newVal) {
  //     // console.log('ROUTE CHANGE: ', newVal);
  //     // if (this.$refs.searchInput !== undefined) {
  //     //   this.$refs.searchInput.replaceInputValue();
  //     // }
  //   },
  // },
};
