<template>
  <div class="search-sidebar--dropdown">
    <div
      v-b-toggle.collapseRentType
      class="d-flex"
      style="justify-content: space-between;"
      @click="open"
    >
      <h3 class="filter--title">{{ $t('addListing.rentType') }}</h3>
      <i :class="!isOpened ? 'ion-chevron-down' : 'ion-chevron-up'"></i>
    </div>
    <b-collapse id="collapseRentType">
      <div class="dropdown-item" v-for="(rentType, key) in rentTypes" :key="key">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            @change="getRentType()"
            :value="rentType.id"
            v-model="rentTypeId"
            :id="`rent-type-${rentType.name}`"
            :disabled="isLoading"
          />
          <label class="custom-control-label" :for="`rent-type-${rentType.name}`">{{
            rentType.name
          }}</label>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SearchMixin from '@/mixins/searchMixin';

export default {
  mixins: [SearchMixin],
  data: () => ({
    isOpened: false,
    fromPage: 'SEARCH',
    isMobileFilter: false,
  }),
  computed: {
    ...mapState({
      rentTypes: state => state.v2.masters.rentTypes,
      rentType: state => state.v2.masters.rentType,
      isLoading: state => state.v2.search.isLoading,
    }),
  },
  methods: {
    async getRentType() {
      if (this.rentTypeId.length === 1) {
        await this.$store.dispatch('v2/masters/getRentTypeById', this.rentTypeId);
      }
    },
    open() {
      this.isOpened = !this.isOpened;
    },
    onUpdateFilter() {},
  },
};
</script>
